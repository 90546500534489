import type { JSX, ParentProps } from "solid-js";
import {
  createEffect,
  createMemo,
  getOwner,
  onCleanup,
  onMount,
  runWithOwner,
} from "solid-js";
import { insert } from "solid-js/web";

interface PortalProps {
  mount: HTMLElement;
  createElement: () => HTMLElement;
  ref?: HTMLElement | ((el: HTMLElement) => void);
  onMount?: closure;
  beforeRemove?: closure;
  removeOverride?: (remove: closure) => void;
  onCleanup?: closure;
}

export function Portal2(props: ParentProps<PortalProps>) {
  const owner = getOwner(); // получаем текущего владельца
  let content: undefined | (() => JSX.Element); // это получается наш реф

  createEffect(() => {
    if (!content) {
      content = runWithOwner(owner, () => {
        return createMemo(() => props.children);
      })!;
    }

    const container = props.createElement();

    insert(container, content);
    props.mount.appendChild(container);

    if (props.ref) {
      (props.ref as unknown as (el: HTMLElement) => void)(container);
    }

    props.onMount && onMount(props.onMount);

    onCleanup(() => {
      props.beforeRemove?.();
      if (props.removeOverride) {
        props.removeOverride(() => container.remove());
      } else {
        container.remove();
      }
      props.onCleanup?.();
    });
  });

  return null;
}
