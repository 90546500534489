import type { Accessor } from "solid-js";
import { Show } from "solid-js";

import { changeIsOpenModal } from "@base/utils/modal-menu";

import { CloseIcon, MiMenu } from "@icons";

export const MenuButton = (props: { isModal: Accessor<boolean> }) => {
  const onClick = () => {
    changeIsOpenModal();
  };

  return (
    <button
      type="button"
      id="open-modal"
      onClick={onClick}
      title={$.global.open_nav_menu}
      aria-label={$.global.open_nav_menu}
      class="icon"
    >
      <Show when={!props.isModal()} fallback={<CloseIcon />}>
        <MiMenu />
      </Show>
    </button>
  );
};
