import { isTesting } from "@libfunc/env";

import type { UserEntry } from "@shared/models/user";

interface InitialData {
  readonly user?: UserEntry;
  readonly folders?: [];
}

interface RootData {
  /**
   * Для каждой страницы эти данные будут разными
   * После прочтения, нужно установить null чтобы потом не читать устаревшие данные на других страницах
   */
  readonly firstPageData?: Record<string, unknown> | null;
  /**
   * Эти данные одни и те же для всех страниц
   */
  readonly initialData?: Readonly<InitialData>;
}

function initRootData(): null | Readonly<RootData> {
  const data = document.getElementById("data");

  if (data?.textContent) {
    try {
      const parsed = JSON.parse(data.textContent);

      if (!isTesting) {
        data.textContent = "null";
      }

      if (typeof parsed === "object") {
        if (data.textContent && isTesting) {
          console.debug("init data:", JSON.parse(data.textContent));
        }
        return Object.freeze(parsed) as RootData;
      }
      console.error("init data is not object!");
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  } else {
    return null;
  }
}

const rootData = initRootData();

declare global {
  function getRootData(): RootData | null;
}

window.getRootData = () => rootData;

export const initUser = rootData?.initialData?.user ?? null;
export const initFolders = rootData?.initialData?.folders ?? null;

export const pullFirstPageData = <T>(key: string): null | T => {
  if (rootData?.firstPageData) {
    const data = rootData.firstPageData[key];
    if (data) {
      rootData.firstPageData[key] = null;
      return data as T;
    }
  }
  return null;
};
