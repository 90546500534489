import { ProgressIcon } from "@icons";

export const Loader = () => {
  return (
    <div
      style={{
        animation: "rotation 2s infinite linear",
        width: "fit-content",
        "align-self": "center",
      }}
    >
      <ProgressIcon />
    </div>
  );
};
