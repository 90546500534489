import { type RoutePreloadFunc, query } from "@solidjs/router";

import type { Entry, Id } from "@libfunc/types";

import type { FormEntry } from "@shared/models/forms/common";
import type {
  FormBodyEntry,
  StaticFormBody,
  StaticFormBodyId,
} from "@shared/models/forms/static-form";

import { getPublishedFormBody } from "@base/api/formBodies";
import { getForm } from "@base/api/forms";
import { pullFirstPageData } from "@base/root-data";
import { changeTitle } from "@base/utils/helpers";

type FormResource = { form: FormEntry; body: FormBodyEntry };

export const getFormCached = query(
  async (formId: Id): Promise<FormResource> => {
    const form = pullFirstPageData<FormEntry>("form");
    const formBody =
      pullFirstPageData<Entry<StaticFormBody, StaticFormBodyId>>("formBody");

    if (form && formBody) {
      return { form, body: formBody };
    }
    const formPromise = getForm(formId);
    const formBodyPromise = getPublishedFormBody(formId);
    const [fetchedForm, fetchedFormBody] = await Promise.all([
      formPromise,
      formBodyPromise,
    ]);

    changeTitle(fetchedForm.name);
    return { form: fetchedForm, body: fetchedFormBody };
  },
  "getForm",
);

export const preloadFormData: RoutePreloadFunc = ({ params }) => {
  // add Promise to props.data in component
  return getFormCached(params.id);
};
