import { simd } from "wasm-feature-detect";

export const isTouchDevice = "ontouchstart" in document.documentElement;
export const isTouchPoints = navigator.maxTouchPoints > 0;
export const isTouchMedia = window.matchMedia("(pointer: coarse)").matches;

export const isOffscreenCanvas = typeof OffscreenCanvas !== "undefined";

export const isSharedWorkers = typeof SharedWorker !== "undefined";

const wasmSupportedFn = (): boolean => {
  try {
    const isObj = typeof WebAssembly === "object";
    const isFunc = typeof WebAssembly.instantiate === "function";

    if (isObj && isFunc) {
      const arr = Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00);
      const module = new WebAssembly.Module(arr);

      if (module instanceof WebAssembly.Module) {
        const instance = new WebAssembly.Instance(module);
        return instance instanceof WebAssembly.Instance;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return false;
};

export const wasmSupported = wasmSupportedFn();

export interface WasmSupported {
  simd: boolean;
}

export const wasmFeatures = async (): Promise<WasmSupported> => {
  const simdSupport = await simd();
  return {
    simd: simdSupport,
  };
};
