import { createComponent, render } from "solid-js/web";

import { init } from "@base/utils/init-user";

import Main from "@comp/blocks/Main";

import { APP } from "./consts/elements";

// import("@base/css/dynamic/index.css");

const userId = init();

setTimeout(() => {
  import("@base/on-init").then(({ onInit }) => onInit(userId));
}, 0);

const app = document.getElementById(APP)!;
render(() => createComponent(Main, {}), app);
