import { createSignal } from "solid-js";

import { MODAL_NAV } from "@base/consts/elements";

const modalSignal = createSignal(false);
export const isModal = modalSignal[0];
const setIsModal = modalSignal[1];
const nav = document.getElementById(MODAL_NAV)!;

const backEventListener = () => {
  if (isModal()) {
    closeMenu();
  }
};

const openModal = () => {
  document.body.style.overflow = "hidden";
  nav.style.display = "flex";
  window.addEventListener("popstate", backEventListener);
  setIsModal(true);
};

const closeMenu = () => {
  document.body.style.overflow = "";
  nav.style.display = "";
  window.removeEventListener("popstate", backEventListener);
  setIsModal(false);
};

export const changeIsOpenModal = (val?: boolean) => {
  const isModalOpen = isModal();
  const value = val ?? !isModalOpen;

  if (value && !isModalOpen) {
    openModal();
  } else if (!value && isModalOpen) {
    closeMenu();
  }
};

// windowResize(() => {
//   if (isModalOpen) {
//     changeIsOpenModal(false);
//   }
// });
